<template>
  <a-card :bordered="false" size="small" style="height: 240px">
    <template slot="title">今日出库情况</template>
    <a-row style="text-align: center; padding: 36px 8px; height: 179px">
      <a-col :span="12">
        <div style="color: #666; font-size: 40px; font-weight: 600">{{ dataItem.total_quantity }}</div>
        <div style="color: #666; font-size: 16px">出库数量</div>
      </a-col>
      <a-col :span="12">
        <div style="color: #666; font-size: 40px; font-weight: 600">{{ dataItem.total_amount }}</div>
        <div style="color: #666; font-size: 16px">出库金额</div>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { stockOutStats } from "@/api/stats";

export default {
  data() {
    return {
      dataItem: {},
    };
  },
  methods: {
    initData() {
      this.getData();
    },
    getData() {
      stockOutStats().then((data) => {
        this.dataItem = data;
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
